
import { defineComponent, ref } from 'vue'
import Search from './components/Search.vue'
export default defineComponent({
  name: 'Sign',
  components: { Search },
  setup() {
    const count = ref('')
    const checkStatus = ref(0)
    const tableData = [
      {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      },
      {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      },
      {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      },
      {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }
    ]
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const fileList = [
      {
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      },
      {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }
    ]
    const handleRemove = (file: any, fileList: any) => {
      console.log(file, fileList)
    }

    return {
      count,
      fileList,
      tableData,
      checkStatus,
      handleClose,
      handleRemove,
      dialogVisible,
      seeDialogVisible
    }
  }
})
