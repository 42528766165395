<template>
  <div class="sign-wrap">
    <Search />
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="date" label="用户名"></el-table-column>
      <el-table-column prop="name" label="剩余条数"></el-table-column>
      <el-table-column prop="name" label="加入日期"></el-table-column>
      <el-table-column label="操作">
        <template>
          <el-button @click="dialogVisible = true" type="text" size="small">添加条数</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加条数" v-model="dialogVisible" width="40%" :before-close="handleClose">
      <el-form>
        <el-row>
          <el-form-item label="新增条数" label-width="100px">
            <el-input v-model="count" autocomplete="off"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import Search from './components/Search.vue'
export default defineComponent({
  name: 'Sign',
  components: { Search },
  setup() {
    const count = ref('')
    const checkStatus = ref(0)
    const tableData = [
      {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      },
      {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      },
      {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      },
      {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }
    ]
    const dialogVisible = ref(false)
    const seeDialogVisible = ref(false)
    const handleClose = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const fileList = [
      {
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      },
      {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }
    ]
    const handleRemove = (file: any, fileList: any) => {
      console.log(file, fileList)
    }

    return {
      count,
      fileList,
      tableData,
      checkStatus,
      handleClose,
      handleRemove,
      dialogVisible,
      seeDialogVisible
    }
  }
})
</script>
<style lang="less" scoped>
.sign-wrap {
  position: relative;
  background-color: #f2f2f2;

  .add-sign {
    top: 20px;
    position: absolute;
    right: 20px;
  }

  .el-upload-list {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
<style scoped>
.el-upload {
  display: inline-block;
  vertical-align: middle;
}
.upload-demo >>> .el-upload-list {
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.change-width >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-col >>> .el-range-editor.el-input__inner {
  width: 100%;
}
</style>
