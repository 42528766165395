<template>
  <div class="search-wrap">
    <el-form label-width="80px" label-position="right">
      <el-row>
        <el-col :span="6">
          <el-form-item label="用户名">
            <el-input v-model="userName" />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" size="medium" @click="search">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'Search',
  setup() {
    const userName = ref('')

    const search = () => {
      console.log(userName.value, '--vv--')
    }

    return {
      search,
      userName
    }
  }
})
</script>
<style lang="less" scoped>
.search-wrap {
  padding-top: 20px;
}
</style>
