
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'Search',
  setup() {
    const userName = ref('')

    const search = () => {
      console.log(userName.value, '--vv--')
    }

    return {
      search,
      userName
    }
  }
})
